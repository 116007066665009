// Theme variables.
$--accent: --accent;
$--accent-background: --accent-background;
$--anchor: --anchor;
$--button: --button;
$--cta: --cta;
$--cta-hover: --cta-hover;
$--divider-color: --divider-color;
$--error: --error;
$--font-color: --font-color;
$--results: --results;

// Default theme.
$theme-map-default: (
  $--accent: #f99b1c,
  $--accent-background: #f6f6f6,
  $--anchor: #1e1e1e,
  $--button: #0096ff,
  $--cta: #ff4600,
  $--cta-hover: #f99b1c,
  $--divider-color: #c8d8dc,
  $--error: #ff4600,
  $--font-color: #1e1e1e,
  $--results: #00a4df,
);
